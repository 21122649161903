// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from 'firebase/app';

// // If you enabled Analytics in your project, add the Firebase SDK for Analytics

import {firebaseConfig} from '../../firebase.config.js';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/database';

import Store from '../Redux/store';
import {login} from '../Redux/actions';
import {FirebaseSchema} from '../../models/index.js';

import {merge, mapValues} from 'lodash';

import {initializeApp} from 'firebase/app';
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth';
import {getDatabase, ref, get} from 'firebase/database';
// import {getAnalytics} from 'firebase/analytics';

import _ from 'lodash';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const authentication = getAuth(app);
export const database = getDatabase(app);

authentication.onAuthStateChanged((user) => {
  if (user) {
    // User is signed in.
    Store.dispatch(login({email: user.email, authenticated: true}));
  } else {
    // No user is signed in.
  }
});

export const signIn = async (email: string, password: string) => {
  await setPersistence(authentication, browserSessionPersistence);
  return signInWithEmailAndPassword(authentication, email, password);
};

export const signOut = async () => authentication.signOut();

export const fetchSubmissions = async () => {
  const sessionsSnapshot = await get(ref(database, 'task_sessions'));
  const data: FirebaseSchema['task_sessions'] = sessionsSnapshot.val();
  const taskSessions = mapValues(data, (o) => {
    return {task_sessions: Object.values(o)};
  });

  const consumptionsSnapshot = await get(ref(database, 'alcohol_consumptions'));
  const alcoholConsumptions = consumptionsSnapshot.val();
  let merged = merge(taskSessions, alcoholConsumptions);
  return _.map(merged, (value, key) => ({
    ...value,
    participant_id: key,
  }));
};

export const fetchParticipants = async () => {
  const snapshot = await get(ref(database, 'participants'));
  return snapshot.val() as FirebaseSchema['participants'];
};

export default {
  signIn,
  signOut,
};
