import {Button as MUIButton, withStyles} from '@material-ui/core';

const Button = withStyles({
  root: {
    margin: 10,
    borderRadius: 10,
    border: 0,
    height: 48,
    padding: '0 30px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0,
  },
  label: {
    textTransform: 'capitalize',
  },
})(MUIButton);

export default Button;
