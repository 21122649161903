import React from 'react';
import styled from 'styled-components/macro';

import {Chip as MUIChip, withStyles, Popover} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {compact} from 'lodash';
import {connect} from 'react-redux';
import DataTable from '../components/DataTable';
import FilterDialog from '../components/FilterDialog';
import {signOut} from '../lib/Firebase';
import {DEFAULT_USER} from '../lib/Redux/defaultState';
import {logout} from '../lib/Redux/actions';
import {AlcoholCapture} from '../lib/Types';
import withRouter from '../hooks/withRouter';
import {NavigateFunction} from 'react-router-dom';
import OktaAuth from '@okta/okta-auth-js';
import {useOktaAuth} from '@okta/okta-react';

const Chip = withStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    right: 160,
    margin: 15,
  },
})(MUIChip);

const toISO = (date: Date | null) =>
  date ? date.toISOString().substr(0, 10).replace(/-/g, '/') : null;

interface Props {
  oktaAuth: OktaAuth;
  history: NavigateFunction;
  user: {
    email: string;
    authenticated: boolean;
  };
  onLogout: () => void;
}

interface State {
  responses: {
    [participantId: string]: {
      participantId: string;
      researchGroupId: string;
      lastSubmissionDate: Date | null;
      submissions: Array<
        AlcoholCapture.Database.v2_0_0.ParticipantInfo &
          AlcoholCapture.Database.v2_0_0.ParticipantSubmission
      >;
    };
  } | null;
  ascending: boolean;
  selectedRows: boolean[];
  filterPopup: boolean;
  downloadPopup: boolean;
  filters: {
    dateFrom: Date | null;
    dateTo: Date | null;
    studyID: 'All' | string;
    participantID: 'All' | string;
  };
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

class DashboardPage extends React.Component<Props, State> {
  private unmounted: boolean = false;

  static defaultProps = {
    user: DEFAULT_USER,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      responses: null,
      ascending: true,
      selectedRows: [],
      filterPopup: false,
      downloadPopup: false,
      filters: {
        dateFrom: null,
        dateTo: null,
        studyID: 'All',
        participantID: 'All',
      },
      open: false,
      anchorEl: null,
    };
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onSort = () => {
    const {ascending} = this.state;
    this.setState({ascending: !ascending});
  };

  onCheck(index: number, checked: boolean) {
    const {selectedRows} = this.state;
    const newSelections = [...selectedRows];

    newSelections[index] = checked;
    this.setState({selectedRows: newSelections});
  }

  onCheckAll() {
    const {selectedRows, responses} = this.state;
    const selectedRowsNo = compact(selectedRows).length;

    this.setState({
      selectedRows: this.applyFilters(responses || {}).map(
        () => selectedRowsNo === 0,
      ),
    });
  }

  applyFilters = (
    // responses: Array<
    //   AlcoholCapture.Database.v1_0_6.ParticipantInfo &
    //     AlcoholCapture.Database.v1_0_6.ParticipantSubmission
    // >,
    responses: {
      [participantId: string]: {
        participantId: string;
        researchGroupId: string;
        lastSubmissionDate: Date | null;
        submissions: Array<
          AlcoholCapture.Database.v2_0_0.ParticipantInfo &
            AlcoholCapture.Database.v2_0_0.ParticipantSubmission
        >;
      };
    },
  ) => {
    const {ascending, filters} = this.state;
    const {
      dateFrom,
      dateTo,
      // studyID,
      participantID,
    } = filters;

    const output = responses
      ? Object.values(responses)
          // .sort(dataSort)
          // .filter(row => studyID === 'All' || row.user.researchId === studyID)
          .filter(
            (row) =>
              participantID === 'All' || row.participantId === participantID,
          )
          .filter(
            (row) =>
              !dateFrom ||
              (row.lastSubmissionDate && row.lastSubmissionDate >= dateFrom),
          )
          .filter(
            (row) =>
              !dateTo ||
              (row.lastSubmissionDate && row.lastSubmissionDate <= dateTo),
          )
      : [];

    return ascending ? output : output.reverse();
  };

  logoutUser() {
    const {onLogout} = this.props;
    signOut().then(() => {
      this.props.oktaAuth.signOut().then(() => {
        onLogout();
      });
    }); // Possible error case needed
  }

  handleClick(event: {currentTarget: any}) {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    const {user} = this.props;
    const {responses, filterPopup, filters} = this.state;
    const {dateFrom, dateTo, studyID, participantID} = filters;

    const data = this.applyFilters(responses || {});

    let filterText = 'Filter: ';
    if (dateFrom) {
      if (dateTo) {
        filterText += `${toISO(dateFrom)} - ${toISO(dateTo)}`;
      } else {
        filterText += `${toISO(dateFrom)} - Today`;
      }
    } else if (dateTo) {
      filterText += `All - ${toISO(dateTo)}`;
    }

    if (studyID !== 'All') {
      if (dateFrom || dateTo) {
        filterText += ', ';
      }
      filterText += studyID;
    }

    if (participantID !== 'All') {
      if (dateFrom || dateTo || studyID !== 'All') {
        filterText += ', ';
      }
      filterText += participantID;
    }

    const noDataText = "There aren't any files to be exported.";

    return (
      <Page>
        <FilterDialog
          open={filterPopup}
          onClose={() => this.setState({filterPopup: false})}
          onFilter={(newFilters) =>
            this.setState({
              filters: newFilters,
              selectedRows: Array(Object.keys(responses || {}).length).fill(
                false,
              ),
            })
          }
          options={{
            // studyID: responses ? responsSes.map(row => row.user.researchId) : [],
            // participantID: responses
            //   ? sortedUniq(
            //       responses.map(row => row.user.researchId).sort(),
            //     ).reduce(
            //       (acc, researchId) => ({
            //         ...acc,
            //         [researchId]: sortedUniq(
            //           responses
            //             .filter(row => row.user.researchId === researchId)
            //             .map(row => row.user.participantId)
            //             .sort(),
            //         ),
            //       }),
            //       {},
            //     )
            //   : {},
            studyID: [],
            participantID: {},
          }}
        />

        {/* <DownloadDialog
          open={downloadPopup}
          onClose={() => this.setState({downloadPopup: false})}
          onDownload={singleFile => {
            let selectedData = data.filter((_, i) => selectedRows[i]);

            // if (singleFile) {
            //   selectedData = selectedData
            //     .map(responseSet => DataExporter(responseSet))
            //     .reduce((acc, elem) => ({
            //       NBTReport: `${acc.NBTReport}\n${elem.NBTReport.slice(
            //         elem.NBTReport.indexOf('\n') + 1,
            //       )}`, // Remove extra headers
            //       SSTReport: `${acc.SSTReport}\n${elem.SSTReport.slice(
            //         elem.SSTReport.indexOf('\n') + 1,
            //       )}`,
            //       QTReport: `${acc.QTReport}\n${elem.QTReport.slice(
            //         elem.QTReport.indexOf('\n') + 1,
            //       )}`,
            //     }));
            // }
            download(
              selectedData,
              'data.zip',
              // !singleFile,
            );
          }}
        /> */}

        <Header>
          <AATAppLogo src={require('../assets/images/modif-i-logo.png')} />

          <UsernameContainer onClick={(e) => this.handleClick(e)}>
            <Username>{user.email}</Username>
            <KeyboardArrowDownIcon />
          </UsernameContainer>
          <Popover
            id={'simple-popover'}
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            onClose={() => this.handleClose()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <LogoutView onClick={() => this.logoutUser()}>
              <OpenInNewIcon style={{color: '535353', marginRight: '9px'}} />
              <Username>Sign out</Username>
            </LogoutView>
          </Popover>
        </Header>

        <TableContainer>
          <DataTable />
        </TableContainer>

        {responses && data.length === 0 && (
          <NoDataLabel>{noDataText}</NoDataLabel>
        )}

        {dateFrom || dateTo || studyID !== 'All' || participantID !== 'All' ? (
          <Chip
            variant="outlined"
            label={filterText}
            onDelete={() =>
              this.setState({
                filters: {
                  dateFrom: null,
                  dateTo: null,
                  studyID: 'All',
                  participantID: 'All',
                },
                selectedRows: Array(Object.keys(responses || {}).length).fill(
                  false,
                ),
              })
            }
          />
        ) : null}
      </Page>
    );
  }
}

const DashboardPageWithOkta = (props) => {
  const {oktaAuth} = useOktaAuth();
  return <DashboardPage oktaAuth={oktaAuth} {...props} />;
};

export default withRouter(
  connect(
    (state: any) => ({user: state.user}),
    (dispatch) => ({onLogout: () => dispatch(logout())}),
  )(DashboardPageWithOkta as any),
);

const Page = styled.div`
  background-color: #f7f9fc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  min-width: 100vw;
  min-height: 56px;
  position: absolute;
  z-index: 5;
  top: 0;
  font-size: 2vmin;
  background-color: white;
`;

const TableContainer = styled.div`
  width: 100vw;
`;

const NoDataLabel = styled.p`
  font-size: 2vmin;
`;

const AATAppLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 16px;
  margin-top: 11px;
  margin-bottom: 11px;
`;

const UsernameContainer = styled.div`
  height: 100%;
  position: absolute;
  right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Username = styled.div`
  color: #535353;
`;

const LogoutView = styled.div`
  width: 140px;
  height: 50px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
