import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import {CircularProgress, withStyles} from '@material-ui/core';
import Button from '../components/Button';
import withRouter from '../hooks/withRouter';

import {useOktaAuth} from '@okta/okta-react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {signInWithCustomToken} from 'firebase/auth';
import {authentication} from '../lib/Firebase';
import {connect} from 'react-redux';

const LoginPage = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const {authenticated} = props.user;

  const {oktaAuth, authState} = useOktaAuth();

  const navigate = useNavigate();

  const performLogin = () => {
    console.log('Redirecting to Okta for authentication.');

    setLoading(true);

    oktaAuth.signInWithRedirect().catch((error) => {
      console.error('Error during signInWithRedirect:', error);
      setError(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (authState?.isAuthenticated && !authenticated) {
      axios
        .get(process.env.REACT_APP_TOKEN_EXCHANGE_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${authState.accessToken.accessToken}`,
          },
        })
        .then((res) => {
          signInWithCustomToken(authentication, res.data);
        })
        .catch((error) => {
          console.error('Error during signInWithCustomToken:', error);
          setError(true);
          setLoading(false);
        });
    }
  }, [
    authState,
    authState?.accessToken,
    authState?.isAuthenticated,
    authenticated,
  ]);

  useEffect(() => {
    if (authenticated) {
      navigate('dashboard');
    }
  }, [authenticated, navigate]);

  return (
    <Page>
      <Title>
        <p>Modif-i Data Exporter</p>
      </Title>
      {loading || authState?.isAuthenticated ? (
        <StyledProgress />
      ) : (
        <StyledButton variant="contained" onClick={() => performLogin()}>
          <p>Sign in with Okta</p>
        </StyledButton>
      )}
    </Page>
  );
};

export default withRouter(
  connect((state: any) => ({user: state.user}))(LoginPage),
);

const StyledButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: '#00B0FF',
    borderRadius: 25,
    fontSize: '20px',
  },
})(Button);

const StyledProgress = withStyles({
  root: {
    color: 'white',
    marginBottom: '15px',
  },
})(CircularProgress);

const Page = styled.div`
  background-color: #006dae;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
`;

const Title = styled.header`
  color: white;
  font-size: 24px;
  font-weight: bold;
`;

const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Error = styled.p`
  font-size: 2vmin;
  color: red;
  margin-left: 40px;
`;
