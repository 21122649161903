export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export function login(user: {email: string | null; authenticated: boolean}) {
  return {type: LOGIN, data: user};
}

export function logout() {
  return {type: LOGOUT};
}
