import React from 'react';
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';

import {connect} from 'react-redux';

import {OktaAuth} from '@okta/okta-auth-js';
import {Security, LoginCallback} from '@okta/okta-react';

import LoginPage from './LoginPage';
import DashboardPage from './DashboardPage';
import oktaConfig from '../okta.config';

// Okta configuration

const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    _originalUri: string,
  ) => {
    // Currently always navigates back to login
    navigate('/', {replace: true});
  };
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    </Security>
  );
};

const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
// TODO: type this state (needs to be a typed for the root reducer).
export default connect((state: any) => ({user: state.user}))(AppWithRouter);
