import React from 'react';
import {useNavigate} from 'react-router-dom';

// https://stackoverflow.com/questions/66465750/withrouter-is-not-exported-from-react-router-dom
const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

export default withRouter;
