import {combineReducers} from 'redux';
import {LOGIN, LOGOUT} from './actions';
import {DEFAULT_USER} from './defaultState';

function user(
  state = DEFAULT_USER,
  action: {
    type: string;
    data: {email?: string | null; authenticated?: boolean};
  },
) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.data,
      };
    case LOGOUT:
      return DEFAULT_USER;
    default:
      return state;
  }
}

function extra(state = null, action: {type: any; data?: any}) {
  // So that user is not the root and for future use
  switch (action.type) {
    default:
      return state;
  }
}

export default combineReducers({
  user,
  extra,
});
