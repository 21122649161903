import React from 'react';
import styled from 'styled-components/macro';

import {
  Dialog,
  TextField,
  Select,
  MenuItem,
  Button as MUIButton,
  withStyles,
} from '@material-ui/core';
import {sortedUniq} from 'lodash';
// import PropTypes from 'prop-types';
// import '../App.css';

const Button = withStyles({
  root: {
    margin: 10,
    borderRadius: 10,
    border: 0,
    height: 48,
    padding: '0 30px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(MUIButton);

const toISO = (date: Date) => date.toISOString().substr(0, 10);

interface Props {
  open: boolean;
  onClose: () => void;
  options: {
    studyID: string[];
    participantID: {[id: string]: string[]};
  };
  onFilter: (params: State) => void;
  // onFilter: ((params: State) => void) | null;
}

interface State {
  dateFrom: Date | null;
  dateTo: Date | null;
  studyID: string;
  participantID: string;
}

class FilterDialog extends React.Component<Props, State> {
  // static defaultProps: Props = {
  //   open: false,
  //   onFilter: null,
  // };

  constructor(props: Props) {
    super(props);
    this.state = {
      dateFrom: null,
      dateTo: null,
      studyID: 'All',
      participantID: 'All',
    };
  }

  render() {
    const {open, onClose, options, onFilter} = this.props;
    const {dateFrom, dateTo, studyID, participantID} = this.state;

    const text = 'You must select a research study id first';

    const participants =
      studyID === 'All'
        ? Object.values(options.participantID).reduce(
            (acc, e) => acc.concat(e),
            [],
          )
        : options.participantID[studyID];

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogContent>
          <Row>
            <LabelItem>Date Range</LabelItem>
            <TextFieldItem
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={dateFrom ? toISO(dateFrom) : ''}
              onChange={({target}) =>
                this.setState({
                  dateFrom: target.value ? new Date(target.value) : null,
                })
              }
            />
            <ToLabelItem>to</ToLabelItem>
            <TextFieldItem
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={dateTo ? toISO(dateTo) : null}
              onChange={({target}) =>
                this.setState({
                  dateTo: target.value ? new Date(target.value) : null,
                })
              }
            />
          </Row>
          <Row>
            <LabelItem>Research Study ID</LabelItem>
            <SelectItem
              value={studyID}
              autoWidth
              onChange={(event) =>
                this.setState({
                  studyID: event.target.value as string,
                  participantID: 'All',
                })
              }>
              <MenuItem key="placeholder" value="All">
                All
              </MenuItem>
              {sortedUniq(options.studyID.sort()).map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </SelectItem>
          </Row>
          <Row>
            <LabelItem>Participant ID</LabelItem>
            <SelectItem
              value={participantID}
              disabled={studyID === 'All'}
              onChange={(event) =>
                this.setState({participantID: event.target.value as string})
              }>
              <MenuItem key="placeholder" value="All">
                All
              </MenuItem>
              {sortedUniq(participants.sort()).map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </SelectItem>
          </Row>
          <Row>
            {studyID === 'All' && <SmallLabelItem>{text}</SmallLabelItem>}
          </Row>
          <Row>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onFilter(this.state);
                onClose();
              }}>
              <p>Apply Filter</p>
            </Button>
          </Row>
        </DialogContent>
      </Dialog>
    );
  }
}

export default FilterDialog;

const DialogContent = styled.div`
  padding: 2.5vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const LabelItem = styled.p`
  flex: 1;
  margin: 2vmin;
`;

const SmallLabelItem = styled.p`
  flex: 1;
  margin: 0;
  font-style: italic;
  font-size: 1.5vmin;
`;

const ToLabelItem = styled.p`
  flex: 1;
  margin: 2vmin;
  text-align: center;
`;

const TextFieldItem = styled(TextField)`
  flex: 2;
  margin: 2vmin;
`;

const SelectItem = styled(Select)`
  flex: 1;
  margin: 2vmin;
`;
